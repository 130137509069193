import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_10, ROUTENAME_STEP_7 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button/index";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import QuestionInsurance from "./QuestionInsurance";

class CustomerInsurance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
    };
  }

  componentDidMount() {
    const { viewStore, history } = this.props;

    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 7
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_7));
    }
    i18next.loadNamespaces("step-10");
  }

  handleOnClickNextButton = (event) => {
    const { loanStore } = this.props;

    if (loanStore.insuranceTypeId === null || loanStore.insuranceTypeId < 0) {
      event.preventDefault();
      this.setState({ validate: true });
    } else {
      this.props.viewStore.save_session(9);
      trackStep(7);
    }
  };

  render() {
    const { t, viewStore, userStore, loanStore } = this.props;
    const userIsTemporarylyEmployed = userStore.incomeSourceId === 8;
    const userIsRetired = userStore.incomeSourceId === 6;
    const userIsOlderSixtyFour = userStore.getAge() >= 64;
    const userIsEmployed = userStore.incomeSourceId === 2;
    const userIsIndependent = userStore.incomeSourceId === 9;
    const userOwnCompany = userStore.incomeSourceId === 3;

    const layout_props = {
      pageTitle: "step-9:pageTitle",
      headlineKey: "step-9:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    if (
      userIsTemporarylyEmployed ||
      userIsRetired ||
      userIsOlderSixtyFour ||
      userOwnCompany
    ) {
      userStore.setStep9skipped(true);
      loanStore.setInsuranceData(null, null);
      return <Redirect to={viewStore.getLocalizedPath(ROUTENAME_STEP_10)} />;
    } else {
      userStore.setStep9skipped(false);
    }

    let formState, formHint;

    if (
      this.state.validate === true &&
      (loanStore.insuranceTypeId === null || loanStore.insuranceTypeId < 0)
    ) {
      formHint = t("global:labels:hint_step");
      formState = "error";
    }

    return (
      <div className={"custom-g-cell"}>
        <Layout data={layout_props}>
          <div className="l-step-group">
            {userIsEmployed || userIsIndependent ? (
              <QuestionInsurance
                userIsEmployed={userIsEmployed}
                userIsIndependent={userIsIndependent}
                hasError={formState}
              />
            ) : null}
            {formState === "error" && (
              <FormValidationHint>{formHint}</FormValidationHint>
            )}
            <ButtonWrapper>
              <ButtonPrimary
                to={viewStore.getLocalizedPath(ROUTENAME_STEP_10)}
                onClick={this.handleOnClickNextButton}
              >
                <span className="a-nexus__text">
                  {t("step-9:next_button_label")}
                </span>
              </ButtonPrimary>
              <ButtonSecondary
                to={viewStore.getLocalizedPath(ROUTENAME_STEP_7)}
              >
                <span className="a-nexus__icon-left">
                  <SVGIcon icon="back-arrow" />
                </span>
                <span className="a-nexus__text">
                  {t("global:labels:back_button")}
                </span>
              </ButtonSecondary>
            </ButtonWrapper>
          </div>
        </Layout>
      </div>
    );
  }
}

CustomerInsurance.propTypes = {
  userStore: PropTypes.object.isRequired,
  t: PropTypes.func,
  viewStore: PropTypes.object,
  loanStore: PropTypes.object,
};

export default withRouter(
  withTranslation("step-9")(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
      loanStore: stores.loan,
    }))(observer(CustomerInsurance))
  )
);
