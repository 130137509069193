import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { validateToken } from "../../../api_clients/document_upload_service";
import { ROUTENAME_DOC_U_S_CONFIRMATION } from "../../../lib/Constants";
import FileStore from "../../../stores/FileStore";
import {
  ButtonPrimary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import LoadingScreen from "../../utils/LoadingScreen/index";
import DocumentUploadNotValid from "./DocumentUpload_not_valid";
import QuestionUploadItem from "./QuestionUploadItem";
import DocumentUploadWrapper from "./QuestionUploadItem/DocumentUploadWrapper";

const AddItem = ({ text, onClick }) => (
  <li className="m-upload-list__item">
    <div className="m-upload m-upload--more">
      <div className="m-upload-content__more">
        <a
          className="a-nexus a-nexus--load-more"
          href="#ignore"
          onClick={onClick}
        >
          <span className="a-nexus__icon-left">
            <SVGIcon icon="plus" />
          </span>
          <span className="a-nexus__text">{text}</span>
        </a>
      </div>
    </div>
  </li>
);

class DocumentUploadStandAlone extends React.Component {
  constructor(props) {
    super(props);

    this.token = props.viewStore.getSearchParamValue("token");
    this.docUploadApiVersion = null; // will come from backend, as response to variant check call; it can be "/v1", "/v2", etc
    this.content = "step-13:components:DocumentUpload";
    this.content2 = "step-13:components:DocumentUploadStandAlone";
    this.block = false;
    this.block2 = false;

    this.state = {
      userContext: {},
      initializing: true,
      tokenError: !this.token,
      uploading: false,
      uploadError: null,
      additionalUploadItems: [],
    };

    if (this.token) {
      validateToken(this.token)
        .then((res) => {
          this.setState({
            initializing: false,
            userContext: this.uncloak_documents(res.variant),
            tokenError: !res.variant.length,
          });

          this.docUploadApiVersion = res.api_version;
        })
        .catch((e) => {
          this.setState({
            initializing: false,
            tokenError: true,
          });
        });
    }
  }

  uncloak_documents(keys) {
    const mapping = {
      isSwiss: true,
      showPartner: false,
      isPartnerSwiss: false,
      PartnerWorks: false,
    };

    if (keys)
      keys.forEach((element) => {
        switch (element) {
          case "A_CH":
            mapping.isSwiss = true;
            break;
          case "A_CH_N":
            mapping.isSwiss = false;
            break;
          case "P_CH":
            mapping.showPartner = true;
            mapping.isPartnerSwiss = true;
            break;
          case "P_CH_N":
            mapping.showPartner = true;
            mapping.isPartnerSwiss = false;
            break;
          case "P_W":
            mapping.PartnerWorks = true;
            break;
          default:
            break;
        }
      });

    return mapping;
  }

  handleOnclick = (event) => {
    // do not leave page, when files to upload are available or upload is running
    event.preventDefault();
    const { t, history, viewStore } = this.props;

    if (FileStore.numberOfFiles === 0) {
      this.setState({
        uploadError: t(this.content2 + ".error_no_file"),
      });
    } else if (!this.block && FileStore.hasInvalidFiles) {
      this.block = true;
      this.setState({
        uploadError: t(this.content + ".error_no_valid"),
      });
    } else {
      this.setState(
        {
          uploading: true,
          uploadError: null,
        },
        () => {
          FileStore.uploadValidNonRejectedFiles(
            this.token,
            this.docUploadApiVersion
          )
            .catch((e) => {
              if (this.block2) return;
              throw e;
            })
            .then(() => {
              history.push(
                viewStore.getLocalizedPath(ROUTENAME_DOC_U_S_CONFIRMATION)
              );
            })
            .catch(() => {
              this.block2 = true;
              this.setState({
                uploading: false,
                uploadError: t(this.content + ".error_other"),
              });
            });
        }
      );
    }
  };

  getRequestList = () => {
    const { t } = this.props;
    const { userContext } = this.state;
    const requestList = [];
    const props = { classnames: "a-text a-text--dark-blue-large" };

    // Request for ID
    if (userContext.isSwiss) {
      requestList.push(
        <label {...props}>{t(this.content2 + ".Applicant_CH")}</label>
      );
    } else {
      requestList.push(
        <label {...props}>{t(this.content2 + ".Applicant_CH_N")}</label>
      );
    }
    // Request for income statements
    requestList.push(
      <label {...props}>
        {t(this.content2 + ".Applicant_income_statements")}
      </label>
    );

    requestList.push(
      <label {...props}>{t(this.content2 + ".Applicant_CH_HOME")}</label>
    );

    // Ask for partners documents if required
    if (userContext.showPartner) {
      if (userContext.isPartnerSwiss) {
        requestList.push(
          <label {...props}>{t(this.content2 + ".Partner_CH")}</label>
        );
      } else {
        requestList.push(
          <label {...props}>{t(this.content2 + ".Partner_CH_N")}</label>
        );
      }
      if (userContext.PartnerWorks)
        requestList.push(
          <label {...props}>
            {t(this.content2 + ".Partner_income_statements")}
          </label>
        );
    }

    return requestList;
  };

  // MAYBE hold visibility of added upload items in store, that when user
  // uploads files successfully and navigates back, we can also uncover
  // the additional uploaded items
  addUploadItem = (event) => {
    event.preventDefault();
    const items = this.state.additionalUploadItems;
    items.push("file");
    this.setState({ additionalUploadItems: items });
  };

  render() {
    const { t, viewStore } = this.props;
    const { additionalUploadItems } = this.state;
    let view;

    // While validating the token, the page is not ready for render
    if (this.state.initializing) {
      view = (
        <div className="l-step-group">
          <div className="l-step-item">
            ...{t(this.content + ".FILE_UPLOAD_INITIALIZING")}
          </div>
        </div>
      );
    }

    // If token is not valid, show error message
    if (this.state.tokenError) {
      view = (
        <div className="l-step-group">
          <div className="l-step-item">
            <DocumentUploadNotValid />
          </div>
        </div>
      );
    } else {
      view = (
        <div className="l-step-group">
          <div className="l-step-item">
            <label className="a-text a-text--dark-blue-large">
              {t(this.content2 + ".disclaimer")}
            </label>
            <h2 className="a-text">{t(this.content + ".subHeadline")}</h2>
            <ul className="m-element-list m-element-list--disc">
              {this.getRequestList().map((item, i) => {
                return (
                  <li className="m-element-list__item" key={i}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>

          <DocumentUploadWrapper>
            <React.Fragment>
              <QuestionUploadItem
                id={0}
                title={t(this.content2 + ".title_document")}
              />
              <QuestionUploadItem
                id={1}
                title={t(this.content2 + ".title_document")}
              />
              <QuestionUploadItem
                id={2}
                title={t(this.content2 + ".title_document")}
              />
              <QuestionUploadItem
                id={3}
                title={t(this.content2 + ".title_document")}
              />
              <QuestionUploadItem
                id={4}
                title={t(this.content2 + ".title_document")}
              />
              {additionalUploadItems.map((item, i) => {
                return (
                  <QuestionUploadItem
                    id={5 + i}
                    title={t(this.content2 + ".title_document")}
                    key={i}
                  />
                );
              })}

              {additionalUploadItems.length < 5 && (
                <AddItem
                  onClick={this.addUploadItem}
                  text={t(this.content2 + ".add_document")}
                />
              )}
            </React.Fragment>
          </DocumentUploadWrapper>

          <div className="l-step-item">
            <h3 className="a-text a-text--dark-blue-large">
              {t(this.content + ".hintsHeadline")}
            </h3>
            <p className="a-text a-text--dark-blue-large">
              {t(this.content2 + ".hint1")}
            </p>
          </div>
          {this.state.uploadError && (
            <FormValidationHint>{this.state.uploadError}</FormValidationHint>
          )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_DOC_U_S_CONFIRMATION)}
              onClick={this.handleOnclick}
              state={this.state.uploading ? "disabled" : null}
            >
              <span className="a-nexus__text">
                {t(this.content2 + ".next_button")}
              </span>
            </ButtonPrimary>
          </ButtonWrapper>
        </div>
      );
    }

    const layout_props = {
      pageTitle: "step-13:pageTitle",
      headlineKey: this.content2 + ".headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: this.state.tokenError ? false : true,
    };

    return (
      <Layout data={layout_props}>
        <LoadingScreen
          showLoadingScreen={this.state.uploading}
          title={t("global:loading_screen_message")}
        />
        {view}
      </Layout>
    );
  }
}

DocumentUploadStandAlone.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-13")(
  withRouter(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
    }))(observer(DocumentUploadStandAlone))
  )
);
