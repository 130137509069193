export const KEY_NONE = "no_insurance";
export const KEY_PPI = "ppi";
export const KEY_PPI_PLUS = "ppi_plus";
export const KEY_PPI_SE = "ppi_self_employed";
export const ID_NONE = 9;
export const ID_PPI = 1;
export const ID_PPI_PLUS = 2;
export const ID_PPI_SE = 1;

const insurances = [
  {
    id: ID_NONE,
    key: KEY_NONE,
    name: "No Insurance",
    rate: 0,
  },
  {
    id: ID_PPI,
    key: KEY_PPI,
    name: "Payment Protect",
    rate: 7.829,
  },
  {
    id: ID_PPI_PLUS,
    key: KEY_PPI_PLUS,
    name: "Payment Protect Plus",
    rate: 6.6,
  },
  {
    id: ID_PPI_SE,
    key: KEY_PPI_SE,
    name: "Payment Protect Selfemployed",
    rate: 7.829,
  },
];

/**
 * @returns {Array<Object>}
 */
export const getInsurances = () => insurances.map((i) => Object.assign({}, i));

/**
 * @param {string} key
 * @returns {Object|null}
 */
export const getInsuranceByKey = (key) => {
  const i = insurances.find((i) => i.key === key);
  return i ? Object.assign({}, i) : null;
};
