import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import cembraLogo from "../../../../assets/logos/cembra-logo.svg";
import {
  KEY_NONE,
  ID_NONE,
  KEY_PPI,
  ID_PPI,
  KEY_PPI_PLUS,
  ID_PPI_PLUS,
  getInsurances,
  getInsuranceByKey,
} from "../../../../data/insuranceData";
import { trackInsurance } from "../../../../helpers/trackingHelper";
import { insurance_limits } from "../../../../lib/Constants";
import InsuranceCard from "../../../styleguides/InsuranceCard";

let I18N_PREFIX_PLUS = "step-9:components:PPIPlusInsurance:";
let I18N_PREFIX = "step-9:components:PPISEInsurance:";

class QuestionInsurance extends React.Component {
  constructor(props) {
    super(props);

    let insuranceTypeId = props.loanStore.insuranceTypeId;
    if (!insuranceTypeId) {
      if (props.userIsEmployed) {
        insuranceTypeId = ID_PPI_PLUS;
      } else {
        insuranceTypeId = ID_PPI;
      }
    }

    this.state = {
      insuranceTypeId,
    };

    this.onClickItemHandler(insuranceTypeId);
  }

  onClickItemHandler = (id) => {
    const { loanStore } = this.props;

    this.setState({
      insuranceTypeId: id,
    });

    loanStore.setInsuranceData(id, Number(this.calculateInsuranceCosts(id)));
  };

  handleRadioChange = (selectedRadio) => {
    trackInsurance(selectedRadio);
    const insurance = getInsuranceByKey(selectedRadio);
    const insuranceId = insurance?.id ?? null;

    this.setState({
      insuranceTypeId: insuranceId,
    });

    if (insuranceId !== null) {
      this.onClickItemHandler(insuranceId);
    }
  };

  calculateInsuranceCosts(insuranceID) {
    const { loanStore } = this.props;
    const rate = getInsurances().filter((p) => p.id === insuranceID)[0].rate;
    let ppiBase = Number(loanStore.calculateFinancingRate(null, "MAX"));

    if (ppiBase > insurance_limits.ppi_max_rate_for_calculation)
      ppiBase = insurance_limits.ppi_max_rate_for_calculation;

    if (insuranceID === getInsuranceByKey(KEY_PPI_PLUS).id) {
      return (
        ((ppiBase + insurance_limits.ppiplus_additional_rate_value) / 100) *
        rate
      ).toFixed(2);
    } else if (insuranceID === getInsuranceByKey(KEY_PPI).id) {
      return ((ppiBase / 100) * rate).toFixed(2);
    } else if (insuranceID === getInsuranceByKey(KEY_NONE).id) {
      return 0;
    } else {
      return "Error";
    }
  }

  render() {
    const { t, userIsEmployed, userIsIndependent, viewStore } = this.props;
    const { insuranceTypeId } = this.state;

    let insurance, insurancePlus, ppiPlusCost, ppiCost, i18nBase;

    if (userIsEmployed) {
      i18nBase = I18N_PREFIX_PLUS;
      insurancePlus = getInsuranceByKey(KEY_PPI_PLUS);
      ppiPlusCost = viewStore.rounding5cents(
        this.calculateInsuranceCosts(insurancePlus.id)
      );
    } else if (userIsIndependent) {
      i18nBase = I18N_PREFIX;
    }
    insurance = getInsuranceByKey(KEY_PPI);
    ppiCost = viewStore.rounding5cents(
      this.calculateInsuranceCosts(insurance.id)
    );

    return (
      <>
        <div className="insurance-card-container">
          {userIsEmployed && (
            <>
              <div
                className="insurance-plus-card"
                onClick={() => {
                  this.handleRadioChange(KEY_PPI_PLUS);
                }}
              >
                <InsuranceCard
                  details={[
                    { text: t(`${i18nBase}plus_details_1`), style: {} },
                    { text: t(`${i18nBase}plus_details_2`), style: {} },
                    { text: t(`${i18nBase}plus_details_3`), style: {} },
                  ]}
                  title={t(`${i18nBase}plus_headline`)}
                  radioId={KEY_PPI_PLUS}
                  isChecked={insuranceTypeId === ID_PPI_PLUS}
                  bonusDetails={t(`${i18nBase}plus_bonus_details`)}
                  bonusDescription={t(
                    `${i18nBase}plus_bonus_details_description`
                  )}
                  cardBanner={true}
                  insuranceMonthlyCost={ppiPlusCost}
                />
              </div>
              <div
                className="insurance-card"
                onClick={() => {
                  this.handleRadioChange(KEY_PPI);
                }}
              >
                <InsuranceCard
                  details={[
                    { text: t(`${i18nBase}details_1`), style: {} },
                    { text: t(`${i18nBase}details_2`), style: {} },
                    { text: t(`${i18nBase}details_3`), style: {} },
                  ]}
                  title={t(`${i18nBase}headline`)}
                  radioId={KEY_PPI}
                  isChecked={insuranceTypeId === ID_PPI}
                  insuranceMonthlyCost={ppiCost}
                />
              </div>
            </>
          )}

          {userIsIndependent && (
            <div
              className="insurance-se-card"
              onClick={() => {
                this.handleRadioChange(KEY_PPI);
              }}
            >
              <InsuranceCard
                details={[
                  { text: t(`${i18nBase}details_1`), style: {} },
                  { text: t(`${i18nBase}details_2`), style: {} },
                  { text: t(`${i18nBase}details_3`), style: {} },
                ]}
                title={t(`${i18nBase}headline`)}
                radioId={KEY_PPI}
                isChecked={insuranceTypeId === ID_PPI}
                cardBanner={true}
                insuranceMonthlyCost={ppiCost}
              />
            </div>
          )}
        </div>

        <div
          className="radio-wrapper radio-wrapper-none"
          onClick={() => this.handleRadioChange(KEY_NONE)}
        >
          <input
            id="no_insurance"
            type="radio"
            name="insurance-option"
            checked={insuranceTypeId === ID_NONE}
            onChange={() => this.handleRadioChange(KEY_NONE)}
          />
          <label htmlFor="no_insurance">
            <span className="insurance-label-description">
              {t(`${I18N_PREFIX}no_insurance_description`)}
            </span>
          </label>
        </div>

        <div className="insurance-details">
          <div className="insurance-details-info insurance-details-info-md">
            {t("step-9:more_details")}
          </div>
          <div className="insurance-details-img">
            <img
              className="insurance-cembra-logo-img"
              src={cembraLogo}
              alt={"cembra_logo"}
            />
            <img
              className="insurance-cover-img"
              src={
                userIsEmployed
                  ? "/files/cover-raten-versicherung-plus.png"
                  : "/files/cover-raten-versicherung-kmu.png"
              }
              alt={t(
                `${userIsEmployed ? I18N_PREFIX_PLUS : I18N_PREFIX}brochure_alt`
              )}
            />
            <div className="insurance-cover-img-div">
              {t("step-9:brochure_text")}
            </div>
          </div>

          <div className="insurance-details-info">
            <div className="insurance-details-info-lg">
              {t("step-9:more_details")}
            </div>
            <a
              className="insurance-download-a"
              href={t(
                `${
                  userIsEmployed ? I18N_PREFIX_PLUS : I18N_PREFIX
                }brochure_href`
              )}
              download
            >
              <div className="insurance-details-download">
                Download PDF - {i18next.resolvedLanguage.toUpperCase()}
              </div>
            </a>
          </div>
        </div>
      </>
    );
  }
}

QuestionInsurance.propTypes = {
  loanStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default withTranslation("step-9")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
    loanStore: stores.loan,
  }))(observer(QuestionInsurance))
);
