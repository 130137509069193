import { action, computed, flow, makeObservable, observable } from "mobx";

import { uploadDocument } from "../api_clients/document_upload_service";
import { allowFileTypes, maxFileSize } from "../lib/Constants";

class File {
  /** {Object} File's data and meta data, usually of type File or Blob */
  file = null;
  /** {boolean} Whether file was uploaded successfully to server */
  uploaded = false;
  /** {boolean} Whether file was uploaded to server and rejected */
  rejected = false;
  /** {Array<string>} List of i18n keys for occured errors */
  errors = [];

  constructor() {
    makeObservable(this, {
      addFileForUpload: action.bound,
      errors: observable,
      file: observable,
      invalid: computed,
      rejected: observable,
      reset: action.bound,
      upload: flow.bound,
      uploaded: observable,
    });
  }

  /**
   * Attaches file and meta data to be uploaded.
   * @param {Object} file Usually an object of type File or Blob.
   * @returns {void}
   */
  addFileForUpload(file) {
    if (file && file.size && file.type) {
      this.reset();

      if (file.size > maxFileSize) {
        this.errors.push("error_upload_file_size");
      }
      if (!allowFileTypes.includes(file.type)) {
        this.errors.push("error_upload_file_type");
      }

      this.file = file;
    }
  }

  /**
   * Clear all data.
   */
  reset() {
    this.errors = [];
    this.file = null;
    this.rejected = false;
    this.uploaded = false;
  }

  /**
   * Uploads file to server.
   * @param {Object} token
   * @param {string} uploadApiVersion
   * @returns {Promise<void>}
   */
  *upload(token, uploadApiVersion) {
    if (!this.file || this.uploaded) return;

    try {
      yield uploadDocument(this.file, token, uploadApiVersion);
      this.errors = [];
      this.uploaded = true;
      this.rejected = false;
    } catch (e) {
      this.errors = ["error_other"];
      this.rejected = true;
      throw e;
    }
  }

  /**
   * @returns {boolean} Whether file fails client side validation.
   */
  get invalid() {
    return !this.rejected && this.errors.length > 0;
  }
}

class FileStore {
  files = [];
  constructor() {
    makeObservable(this, {
      files: observable,
      hasInvalidFiles: computed,
      hasRejectedFiles: computed,
      numberOfFiles: computed,
      uploadValidNonRejectedFiles: action.bound,
    });

    for (var i = 0; i < 16; i++) {
      this.files.push(new File());
    }
  }

  /**
   * Uploads all valid (correct file type and size), unrejected and not yet
   * successfully uploaded files.
   * @param {Object} token
   * @param {uploadApiVersion} string
   * @returns {Promise<void>}
   */
  uploadValidNonRejectedFiles(token, uploadApiVersion) {
    return Promise.all(
      this.files
        .filter((f) => !f.invalid && !f.rejected && !f.uploaded)
        .map((f) => f.upload(token, uploadApiVersion))
    ).then(() => {});
  }

  /**
   * @returns {boolean} Whether there are invalid files that not have been
   *  uploaded to the server yet.
   */
  get hasInvalidFiles() {
    return this.files.some((f) => !f.uploaded && f.invalid);
  }

  /**
   * @returns {boolean} Whether there are files rejected by the server.
   */
  get hasRejectedFiles() {
    return this.files.some(
      (f) => !f.uploaded && f.rejected && f.errors.length > 0
    );
  }

  /**
   * @returns {number} Total number of all added files (regardless of their status).
   */
  get numberOfFiles() {
    return this.files.filter((f) => f.file).length;
  }
}

export default new FileStore();
