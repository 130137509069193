import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";

class InsuranceCard extends React.Component {
  render() {
    const {
      details,
      title,
      radioId,
      isChecked,
      bonusDetails,
      bonusDescription,
      cardBanner,
      onChange,
      insuranceMonthlyCost,
    } = this.props;

    return (
      <div className="card">
        <div className={"card-banner"}>
          {cardBanner && (
            <div className={"card-banner-style"}>{t("step-9:card_banner")}</div>
          )}
        </div>
        <span className="card-title">{title}</span>

        <div className="card-content">
          <ul>
            {details.map((d, i) => {
              const detailText = typeof d === "string" ? d : d.text;
              const detailStyle =
                typeof d === "object" && d.style ? d.style : {};

              return (
                <li
                  key={`${detailText.substring(0, 10)}${i}`}
                  style={details.length === i + 1 ? { marginBottom: 0 } : null}
                >
                  <span style={detailStyle}>{detailText}</span>
                </li>
              );
            })}

            {bonusDetails && (
              <li className={"bonus-details-card-content"}>
                <span className={"card-bonus-details-span"}>
                  {bonusDetails}
                </span>
                <div className={"card-bonus-details"}>{bonusDescription}</div>
              </li>
            )}
          </ul>
          <hr />

          <div className="radio-wrapper">
            <input
              type="radio"
              id={radioId}
              name="insurance-option"
              onChange={() => onChange(radioId)}
              checked={isChecked}
            />

            <label className="radio-wrapper-label" htmlFor={radioId}>
              <span>
                {`${t("global:labels:currency_CHF")}`} {insuranceMonthlyCost}
              </span>
              <p className="insurance-label-monthly">
                {t("global:labels:monthly")}
              </p>
              <p className="insurance-label-description">
                {`${t("step-9:secure")}`}
              </p>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

InsuranceCard.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        style: PropTypes.object,
      }),
    ])
  ).isRequired,
  title: PropTypes.string.isRequired,
  radioId: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  bonusDetails: PropTypes.string,
  bonusDescription: PropTypes.string,
  cardBanner: PropTypes.bool,
  insuranceMonthlyCost: PropTypes.string.isRequired,
};

export default InsuranceCard;
